/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { Button, Go, Grid, ProfileIcon } from "~components";
import { useApp, useAuth, useTheme } from "~hooks";
import { Logomark } from "~components/_common/Logos/index.jsx";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as Token } from "~assets/icons/token-new.svg";

const Header = () => {
  // ==========================================================================
  // context / ref / state

  const {
    loginActive,
    setLoginActive,
    menuActive,
    setMenuActive,
    newsletterActive,
    setNewsletterActive,
    notification,
    registerActive,
    setRegisterActive
  } = useApp();

  const { account, initialized, loggedIn } = useAuth();

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [contactActive, setContactActive] = useState(false);
  const [contactMessage, setContactMessage] = useState(``);
  const [signedIn, setSignedIn] = useState(false);

  // ==========================================================================
  // variables

  const popUpCSS = `
    animation: 0.75s ease-in-out appear forwards;
    opacity: 0;
  `;

  const buttonTW = tw`h-10 relative block pt-2 pr-2 pb-2 pl-2 overflow-hidden rounded-lg`;

  const buttonCSS = [
    css`
      ${colourTransitionCSS};

      background: ${lowImpactMode ? colours.background : colours.foreground};
      border: 1px solid
        ${lowImpactMode ? colours.foreground : colours.background};
      color: ${lowImpactMode ? colours.foreground : colours.background};

      .menu-line {
        background: ${lowImpactMode ? colours.foreground : colours.background};
      }

      svg {
        transition: 0.3s ease fill;
      }

      ${MEDIA_QUERIES.hoverable} {
        transition: 0.3s ease opacity;

        opacity: 0.8;
      }
    `,
    buttonTW
  ];

  const inverseButtonCSS = [
    css`
      ${colourTransitionCSS};

      background: ${lowImpactMode ? colours.foreground : colours.background};
      border: 1px solid
        ${lowImpactMode ? colours.background : colours.foreground};
      color: ${lowImpactMode ? colours.background : colours.foreground};

      .menu-line {
        background: ${lowImpactMode ? colours.background : colours.foreground};
      }

      svg {
        transition: 0.3s ease fill;
      }

      ${MEDIA_QUERIES.hoverable} {
        &:hover {
          transition: 0.3s ease opacity;

          opacity: 0.8;
      }
    `,
    buttonTW
  ];

  // ==========================================================================
  // methods

  //

  // ==========================================================================
  // lifecycle

  useEffect(() => {
    setSignedIn(account && loggedIn);
  }, [account, loggedIn]);

  // ==========================================================================
  // render

  return (
    <header
      css={[
        css`
          pointer-events: none;
        `,
        tw`w-full fixed top-0 right-0 left-0 z-50`
      ]}
    >
      <div
        css={[
          css`
            ${colourTransitionCSS};
            pointer-events: auto;
            background: ${lowImpactMode
              ? colours.foreground
              : colours.background};
            color: ${lowImpactMode ? colours.background : colours.foreground};
          `,
          tw`relative z-20 border-b`
        ]}
      >
        <Grid>
          <div
            css={[
              css`
                grid-column: 1 / -1;
              `,
              tw`relative flex items-center justify-between md:justify-center pt-2 md:pt-6 pb-2 md:pb-6`
            ]}
          >
            <div css={[tw`w-2/5 relative hidden md:flex`]}>
              <div
                css={[
                  css`
                    ${popUpCSS}
                    animation-delay: 0.5s;
                  `
                ]}
              >
                <button
                  type="button"
                  css={[
                    ...inverseButtonCSS,
                    css`
                      padding: 0 1.5rem;
                      margin-right: 1rem;
                    `
                  ]}
                  onClick={() => {
                    setLoginActive(false);
                    setRegisterActive(false);

                    if (newsletterActive) {
                      setNewsletterActive(false);
                    } else {
                      setMenuActive(!menuActive);
                    }
                  }}
                >
                  <div css={[tw`relative flex items-center justify-center`]}>
                    <p
                      css={[
                        css`
                          margin-bottom: -2px;
                        `,
                        tw`w-12 block font-body text-button text-left`
                      ]}
                    >
                      {menuActive || newsletterActive ? `Close` : `Menu`}
                    </p>

                    <div css={[tw`w-6 relative block ml-3`]}>
                      <div
                        className="menu-line"
                        css={[
                          css`
                            transition: transform ease 0.5s;

                            transform: ${menuActive || newsletterActive
                              ? `translate3d(0, 6px, 0) rotate(45deg)`
                              : `translate3d(0, 0, 0) rotate(0deg)`};
                          `,
                          tw`w-full h-[2px] mb-1 relative block`
                        ]}
                      />
                      <div
                        className="menu-line"
                        css={[
                          css`
                            transition: transform ease 0.5s;

                            transform: scaleX(
                              ${menuActive || newsletterActive ? `0` : `1`}
                            );
                          `,
                          tw`w-full h-[2px] mb-1 relative block`
                        ]}
                      />
                      <div
                        className="menu-line"
                        css={[
                          css`
                            transition: transform ease 0.5s;

                            transform: ${menuActive || newsletterActive
                              ? `translate3d(0, -6px, 0) rotate(-45deg)`
                              : `translate3d(0, 0, 0) rotate(0deg)`};
                          `,
                          tw`w-full h-[2px] relative block`
                        ]}
                      />
                    </div>
                  </div>
                </button>
              </div>

              {loggedIn && (
                <div
                  css={[
                    css`
                      ${popUpCSS}
                      position: relative;
                    `,
                    tw`hidden md:block`
                  ]}
                >
                  <button
                    type="button"
                    css={inverseButtonCSS}
                    onClick={() => {
                      if (!signedIn) {
                        return;
                      }

                      setContactActive(!contactActive);
                    }}
                  >
                    <p
                      css={[
                        css`
                          margin-bottom: -1px;
                        `,
                        tw`block pr-2 pl-2 font-body text-button`
                      ]}
                    >
                      Get in touch
                    </p>
                  </button>

                  <div
                    css={[
                      css`
                        transition: 0.5s ease opacity, 0.5s ease transform;

                        transform: translate3d(
                          0,
                          ${contactActive ? `0` : `-1rem`},
                          0
                        );
                        opacity: ${contactActive ? `1` : `0`};
                        pointer-events: ${contactActive ? `auto` : `none`};

                        width: 330px;
                        bottom: -220px;
                      `,
                      tw`absolute left-0 z-50 p-6 bg-green-dark border-green-light border rounded-md overflow-hidden`
                    ]}
                  >
                    <textarea
                      css={[
                        css`
                          height: 100px;
                          background: transparent;
                          resize: none;

                          // outline: none; // todo: accessibility

                          &::-webkit-input-placeholder {
                            color: ${getColor(`green-light`)};
                            font-style: italic;
                          }
                          &::-moz-placeholder {
                            color: ${getColor(`green-light`)};
                            font-style: italic;
                          }
                          &:-ms-input-placeholder {
                            color: ${getColor(`green-light`)};
                            font-style: italic;
                          }
                          &:-moz-placeholder {
                            color: ${getColor(`green-light`)};
                            font-style: italic;
                          }
                        `,
                        tw`w-full relative block font-body text-b1-md`
                      ]}
                      onChange={(e) => {
                        setContactMessage(e.currentTarget.value);
                      }}
                      placeholder="Write us a message"
                    />

                    <a
                      href={`mailto:hello@thewip.work?subject=The%20WIP%20Enquiry&body=${contactMessage}`}
                    >
                      <Button
                        _css={[tw`mt-4 px-6`]}
                        color="green-light"
                        onClick={() => {
                          setContactActive(false);
                        }}
                        text="Send"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div
              css={[
                css`
                  ${popUpCSS}
                `,
                tw`w-1/2 md:w-1/5 relative md:flex md:items-center md:justify-center`
              ]}
            >
              <Go
                to={signedIn ? `/app/dashboard` : `/`}
                onClick={() => {
                  setMenuActive(false);
                  setRegisterActive(false);
                }}
              >
                <div
                  css={[
                    css`
                      width: 38px;

                      ${MEDIA_QUERIES?.desktop} {
                        width: 48px;
                      }
                    `,
                    tw`relative block`
                  ]}
                >
                  <Logomark
                    _css={[tw`w-full relative block`]}
                    color={
                      lowImpactMode ? colours.background : colours.foreground
                    }
                  />
                </div>
              </Go>
            </div>

            <button
              type="button"
              onClick={() => {
                if (menuActive) {
                  setMenuActive(false);
                } else if (newsletterActive) {
                  setNewsletterActive(false);
                } else if (registerActive) {
                  setMenuActive(false);
                  setRegisterActive(false);
                } else {
                  setMenuActive(true);
                }
              }}
              css={[tw`w-10 h-10 relative block md:hidden pr-1 pl-1`]}
            >
              <div
                css={[
                  tw`w-full h-full relative flex flex-col items-center justify-center`
                ]}
              >
                {Array(3)
                  .fill(null)
                  .map((_, lineIndex) => {
                    const key = `menu-line-${lineIndex}`;

                    let transform = `translate3d(0, 0, 0)`;

                    switch (lineIndex) {
                      case 0:
                        transform =
                          menuActive || newsletterActive || registerActive
                            ? `translate3d(0, 9px, 0) rotate(45deg)`
                            : `rotate(0)`;
                        break;

                      case 1:
                        transform =
                          menuActive || newsletterActive || registerActive
                            ? `scaleX(0)`
                            : `scaleX(1)`;

                        break;

                      case 2:
                        transform =
                          menuActive || newsletterActive || registerActive
                            ? `translate3d(0, -9px, 0) rotate(-45deg)`
                            : `rotate(0)`;
                        break;

                      default:
                        break;
                    }

                    return (
                      <div
                        key={key}
                        css={[
                          css`
                            transition: 0.3s ease transform;

                            transform: ${transform};

                            height: 1px;
                            margin: ${lineIndex === 1 ? `8px 0` : `0`};
                          `,
                          tw`w-full relative block bg-green-light`
                        ]}
                      />
                    );
                  })}
              </div>
            </button>

            <div css={[tw`w-2/5 relative hidden md:block`]}>
              {initialized && (
                <>
                  {(signedIn && (
                    <div
                      css={[
                        css`
                          ${popUpCSS}
                          animation-delay: 0.25s;
                        `,
                        tw`w-full relative flex items-center justify-end`
                      ]}
                    >
                      <div
                        css={[
                          css`
                            ${popUpCSS}
                          `
                        ]}
                      >
                        <Go
                          to="/app/profile"
                          _css={[
                            tw`h-auto relative flex items-center justify-center mr-3`
                          ]}
                        >
                          <button type="button" css={[tw`w-10 h-10`]}>
                            <ProfileIcon />
                          </button>
                        </Go>
                      </div>

                      <div
                        css={[
                          css`
                            ${popUpCSS}
                          `
                        ]}
                      >
                        <Go to="/app/profile">
                          <button
                            type="button"
                            css={buttonCSS}
                            onClick={() => {
                              setMenuActive(false);
                              setRegisterActive(false);
                            }}
                          >
                            <div
                              css={[
                                tw`relative flex items-center justify-between pr-2 pl-2`
                              ]}
                            >
                              <Token
                                css={[tw`w-5 h-5 relative block`]}
                                fill={
                                  lowImpactMode
                                    ? colours.foreground
                                    : colours.background
                                }
                              />

                              {account !== null && (
                                <p
                                  css={[
                                    css`
                                      min-width: 1.5rem;
                                    `,
                                    tw`block ml-2 font-body text-button text-right`
                                  ]}
                                >
                                  {account.tokens}
                                </p>
                              )}
                            </div>
                          </button>
                        </Go>
                      </div>

                      {/* <div
                          css={[
                            css`
                              ${popUpCSS}
                            `,
                            tw`ml-3`
                          ]}
                        >
                          <button
                            type="button"
                            css={buttonCSS}
                            onClick={() => {
                              if (!loggedIn) {
                                return;
                              }

                              doLogout();
                            }}
                          >
                            <p
                              css={[
                                css`
                                  margin-bottom: -1px;
                                `,
                                tw`block pr-2 pl-2 font-body text-button`
                              ]}
                            >
                              Logout
                            </p>
                          </button>
                        </div> */}
                    </div>
                  )) || (
                    <div
                      css={[
                        css`
                          ${popUpCSS}
                          animation-delay: 0.5s;
                        `,
                        tw`w-full relative flex items-center justify-end`
                      ]}
                    >
                      <Go to="/app/login">
                        <Button
                          _css={[
                            css`
                              ${inverseButtonCSS};
                            `,
                            tw`w-24 mr-4`
                          ]}
                          onClick={() => {
                            // setLoginActive(!loginActive);
                            setNewsletterActive(false);
                            setMenuActive(false);
                            setRegisterActive(false);
                          }}
                          text={loginActive ? `Close` : `Login`}
                        />
                      </Go>

                      <Button
                        _css={[
                          css`
                            ${buttonCSS};
                          `,
                          tw`w-24`
                        ]}
                        onClick={() => {
                          setNewsletterActive(false);
                          setLoginActive(false);
                          setMenuActive(false);
                          setRegisterActive(!registerActive);
                        }}
                        text={registerActive ? `Close` : `Sign up`}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
      </div>

      {/* // notification area. todo: review with design // */}
      <div
        css={[
          css`
            transition: 0.5s ease opacity, 0.5s ease transform;

            transform: translate3d(0, ${notification ? `0` : `-100%`}, 0);
          `,
          tw`w-full relative py-2 bg-charcoal text-wheat`
        ]}
      >
        <Grid>
          <div css={[tw`col-span-12 relative`]}>
            <p
              css={[
                css`
                  opacity: ${notification ? `1` : `0`};
                `,
                tw`font-body text-b1 md:text-b1-md`
              ]}
            >
              {(notification && notification) || `Notification area.`}
            </p>
          </div>
        </Grid>
      </div>
    </header>
  );
};

export default Header;
